<script setup>
import {computed, onMounted, ref} from "vue";

const props = defineProps({
    type: {
        type: String,
        default: "text",
    },
    modelValue: [String, Number],
    isInfo: Boolean,
    isError: Boolean,
    isWarning: Boolean,
    isSuccess: Boolean,
    rounded: Boolean,
    roundedTop: Boolean,
    roundedBottom: Boolean,
    canBeginWithZero: Boolean,
    size: {
        type: String,
        default: "md",
        validator(value) {
            return ["xs", "sm", "md", "lg", "xl"].includes(value);
        },
    },
});

const emit = defineEmits(["update:modelValue"]);

const compType = computed(() =>
    ["number", "decimal"].some((v) => props.type.includes(v))
        ? "text"
        : props.type
);

const input = ref(null);

defineExpose({focus: () => input.value.focus()});

const validateNumber = (e) => {
    let block = false;
    const string = e.target.value;
    const value = e.key;

    if (props.type !== "number") return;

    if (!/^\d*$/.test(value)) block = true;

    if (string === "0" && !props.canBeginWithZero) block = true;

    if (block) e.preventDefault();
};

const validateDecimal = (e) => {
    let block = false;
    const string = e.target.value;
    const value = e.key;
    const position = e.target.selectionStart;
    const newValue = `${string.slice(0, position)}${value}${string.slice(
        position
    )}`;

    if (props.type !== "decimal") return;

    if (!/^\d+(\.)?\d{0,2}$/.test(newValue)) block = true;

    if (string === "0" && value !== ".") block = true;

    if (block) e.preventDefault();
};

const keyPress = (e) => {
    if (props.type === "number") return validateNumber(e);
    else if (props.type === "decimal") return validateDecimal(e);
    else return null;
};

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus();
    }
});
</script>

<template>
    <input
        ref="input"
        :class="[
            'input',
            {
                'input-rounded': rounded,
                'input-rounded-top': roundedTop,
                'input-rounded-bottom': roundedBottom,
                'input-info': isInfo,
                'input-error': isError,
                'input-warning': isWarning,
                'input-success': isSuccess,
            },
            size ? `input-${size}` : '',
        ]"
        :type="compType"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keypress="keyPress"
    />
</template>
